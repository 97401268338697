<template>
  <v-container fluid class="hero">
    <v-row>
      <v-col cols="12" md="6">
        <v-form ref="PaymentCreateForm" v-model="formValid">

          <h1>Create Payment Schedule</h1>

          <v-col md="6">
              <user-edit-picker
                :label="'Employee Name'"
                :initial="payment.employee"
                v-model="payment.employee"
                :chosen-user.sync="payment.employee"
                :role="'employee'"
              />
          </v-col>

          <v-col md="6">
            <v-text-field v-model="payment.amount" label="Amount" type="number"></v-text-field>
          </v-col>

          <v-row>
            <v-col cols="6">
                <div>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="payment.processBy"
                        label="Schedule Payment"
                        prepend-icon="mdi-calendar"
                        readonly
                        type="date"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="payment.processBy"
                      outlined
                      :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)"
                      min="1900-01-01"
                      @change="saveSchedule"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-col>
            <v-col cols="6">
              <v-select
                label="Period"
                :items="items"
                v-model="payment.period"
              ></v-select>
            </v-col>
          </v-row>

          <v-btn color="white" class="grey--text" elevation="0" @click="[save()]">Create</v-btn>
        </v-form>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="absolute-center"
        v-if="
          $vuetify.breakpoint.md ||
          $vuetify.breakpoint.lg ||
          $vuetify.breakpoint.xl
        "
      >
        <v-avatar class="" size="400" tile>
          <v-img src="../../assets/FairwageLogo.png"></v-img>
        </v-avatar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Payment } from "@/models/Payment";
import { User } from "@/models/User";
import { gql } from "apollo-boost";
import UserEditPicker  from "./../../components/Base/User/UserEditPicker.vue";

export default {
  components: {
    UserEditPicker
  },
  name: "Schedule",
  mounted() {
    this.$emit("update:pageName", this.$options.name);
  },
  data() {
    return {
      selectedItem: 1,
      isLoading: false,
      menu: false,
      id: "",
      items: ["1st week", "2nd week", "3rd week", "last week","1st bi-week", "2nd bi-week", "monthly"],
      payment: {
        employer: "",
        employee: "",
        status: "",
        period: "",
        processBy: null,
        amount: 0,
      },
      // payment: new Payment(),
    };
  },
  apollo: {
    me: {
      query: gql`
        query {
          me {
            id
            firstName
            lastName
          }
        }
      `,
      fetchPolicy: "no-cache",
      result() {
        const keys = Object.keys(this.user.location);
        if (this.me.location) {
          for (const key of keys) {
            this.user.location[key] = this.me.location[key];
          }
        }

        this.id = this.me.id;
      },
    },
  },
  methods: {
    saveSchedule(date) {
      this.$refs.menu.save(date);
    },
    async save() {
      this.isLoading = true;
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation createPayment($payment: PaymentCreateInput!) {
              createPayment(payment: $payment) {
                id
              }
            }
          `,
          variables: {
            payment: {
              employer: this.me.id,
              employee: self.payment.employee,
              status: "scheduled",
              processBy: self.payment.processBy,
              period: self.payment.period,
              amount: parseFloat(self.payment.amount, 2),
            },
          },
        });

        if (response?.data?.createPayment?.id) {
          this.$swal(
            "Success!",
            "Profile details was successfully updated",
            "success"
          );
        } else {
          this.$swal("Error!", "Something went wrong, please try again later");
        }

        this.isLoading = false;
      } catch (e) {
        this.$swal("Error!", e.message, "error");
        this.isLoading = false;
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.herso {
  background: url("../../assets/landing.png") no-repeat center center fixed !important;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

.v-sheet.v-card {
  cbackground: #6dd984;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.theme--light.v-list {
  background: #6dd984;
}

.card-header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
}

.select-card {
  padding: 10px 30px;
  min-height: 60px;
  position: relative;
}

.top-sect .v-btn {
  width: calc(100% - 67px);
  min-height: 70px;
}

.top-sect .v-btn__content {
  color: #eeecf1 !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  letter-spacing: 0.75px;
}

.top-sect .v-card__actions {
  position: absolute;
  right: 10px;
  top: 10px;
}

.top-sect .v-icon.v-icon {
  color: #ffffff;
}

.select-list {
  margin-top: 10px;
}

.ico-point {
  font-size: 30px;
}

.app-btn {
  height: auto;
  min-height: 10px;
  background-color: #fff;
  min-width: 0;
  border-radius: 12px;
  padding: 0;
}

.app-btn .v-btn__content {
  display: block !important;
  padding: 10px;
}

.v-application .secondary.app-btn {
  background-color: #f1f3f6 !important;
  border-color: #f1f3f6 !important;
  color: #3f3d56;
  box-shadow: none;
}
.v-application .primary.app-btn {
  background-color: #6dd984 !important;
  border-color: #6dd984 !important;
  color: #fff;
}

.app-btn .v-btn__content i {
  margin-bottom: 10px;
  border: 2px solid;
  border-radius: 50%;
}

.theCaleSect,
.btn-group {
  text-align: center;
}

/*/////////////////////////*/
.forCale {
  background-color: white;
  padding: 10px !important;
  overflow: hidden;
}

.forCale .names {
  background: #a5a6f6;
  border-radius: 10px;
}

.v-btn:not(.v-btn--round).v-size--default.app-btn {
  height: auto;

  min-width: auto;
  padding: 10px 10px !important;
}

/*/////////////////////////*/

.name-selecter-parent {
  border: 4px solid#A5A6F6;
  margin-bottom: 30px;
  border-radius: 10px;
  background: #5d5fef;
}

.name-selecter > button {
  width: 100%;
  position: relative;
}

.name-selecter > button i {
  position: absolute;
  right: 0;
}

/*history*/
.forlist.theme--light.v-list {
  background: #fff;
}

.forlist.theme--light.v-list .v-list-item {
  border-bottom: 1px solid #dadada;
}

</style>

